<template>
  <div class="about">
    <Card digit="02" ref="card">
      <div class="about__content">
        <TickerHeadline>ABOUT</TickerHeadline>

        <div class="about__item">
          <div class="card__row about__item-row card__row--full">
            <h4>GOALS</h4>
            <p>
              As a creative frontend developer with focus on UX/UI, my goal is to create beautiful and exciting digital products.
              <br />
              I am open for freelance work.
            </p>
          </div>

          <div class="card__row card__row--full card__row--list">
            <h4>TOOLS I PREFER</h4>
            <ul>
              <li>
                VUE.JS
              </li>
              <li>
                NUXT.JS
              </li>
              <li>
                STRAPI
              </li>
              <li>
                CONTENTFUL
              </li>
              <li>
                GSAP
              </li>
              <li>
                GIT
              </li>
              <li>
                PASSION
              </li>

            </ul>
          </div>
        </div>

        <TickerHeadline delay="0.5">EXPERIENCE</TickerHeadline>

        <div class="about__item about__item--last">
          <Collapsible trigger="SINCE 2013 | FREELANCE" @toggled="onCollapsible">
            <div class="card__row card__row--full about__item-row">
              <p>
                Due to my time as a freelance developer i worked for numerous
                big brands & products.
              </p>
            </div>

            <div class="card__row card__row--full about__item-row">
              <h4>BRANDS</h4>
               <ul>
                <li>Mercedes-Benz</li>
                <li>Coca-Cola</li>
                <li>Deutsche Bahn</li>
                <li>Audi</li>
                <li>Zalando</li>
                <li>BMW</li>
                <li>adidas</li>
                <li>...</li>
              </ul>
            </div>

            <div class="card__row card__row--full about__item-row">
              <h4>CLIENTS</h4>
              <ul>
                <li>HENNE/ORDNUNG</li>
                <li>OSK</li>
                <li>SCHOLZ&VOLKMER</li>
                <li>UFOMAMMOOT</li>
                <li>goldene botschaft</li>
                <li>JvM NEXT</li>
                <li>antoni</li>
                <li>heimat active</li>
                <li>...</li>
              </ul>
            </div>

          </Collapsible>
          <Collapsible trigger="2004 - 2013 | PERMANENT | SCHOLZ&VOLKMER"  @toggled="onCollapsible">
            <div class="card__row card__row--third about__item-row">
              <h4>AGENCY</h4>
              <p>SCHOLZ&VOLKMER <br />Wiesbaden, Berlin</p>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>LEVELS</h4>
              <ul>
                <li>JUNIOR</li>
                <li>MEDIOR</li>
                <li>SENIOR</li>
              </ul>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>TASKS</h4>

              <ul>
                <li>TEAM LEAD</li>
                <li>PROJECT LEAD</li>
                <li>FRONTEND DEVELOPMENT</li>
              </ul>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>BRANDS</h4>

              <ul>
                <li>COCA-COLA</li>
                <li>DEUTSCHE BAHN</li>
                <li>LUFTHANSA</li>
                <li>MERCEDES-BENZ</li>
                <li>SWAROWSKI</li>
                <li>SAMSUNG</li>
                <li>PANASONIC</li>
              </ul>
            </div>
          </Collapsible>
          <Collapsible trigger="2003 - 2004 | FREELANCE"  @toggled="onCollapsible">
            <div class="card__row card__row--full about__item-row">
              <h4>AGENCIES</h4>
              <p>screenbow Wiesbaden,<br />SCHOLZ&VOLKMER Wiesbaden</p>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>TASKS</h4>

              <ul>
                <li>FLASH DEVELOPMENT</li>
                <li>UX / UI</li>
              </ul>
            </div>
          </Collapsible>
          <Collapsible trigger="2001 - 2003 | MEDIADESIGN TRAINING"   @toggled="onCollapsible">
            <div class="card__row card__row--half about__item-row">
              <h4>AGENCY</h4>
              <p>Cynaps Audio Visual Systems,<br />Frankfurt/Main</p>
            </div>

            <div class="card__row card__row--half about__item-row">
              <h4>School</h4>
              <p>Gutenbergschule Fachoberschule,<br />Frankfurt/Main</p>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>TASKS</h4>
              <ul>
                <li>FLASH DEVELOPMENT</li>
                <li>UX / UI</li>
              </ul>
            </div>
          </Collapsible>
          <Collapsible trigger="2001 | INTERNSHIP"  @toggled="onCollapsible">
            <div class="card__row card__row--full about__item-row">
              <h4>AGENCY</h4>
              <p>Mapsolute GmbH, Frankfurt/Main</p>
            </div>
            <div class="card__row card__row--full about__item-row">
              <h4>TASKS</h4>
              <ul>
                <li>UX / UI</li>
                <li>CUSTOM MAP INTERFACES</li>
              </ul>
            </div>
          </Collapsible>

          <ContentEnd :next="{target:'projects',slug:'','label':'PROJECTS'}"/>
        </div>

      </div>
    </Card>
  </div>
</template>

<script>
import DataMixin from '@/mixins/DataMixin'
import AppearMixin from '@/mixins/AppearMixin'
import Card from '@/components/Card'
import TickerHeadline from '@/components/TickerHeadline'
import Collapsible from '../components/Collapsible.vue'
import ContentEnd from '../components/ContentEnd.vue'
export default {
  components: { Card, TickerHeadline, Collapsible, ContentEnd },
  mixins: [AppearMixin, DataMixin],
  data () {
    return {
      activeCollapsible: undefined
    }
  },
  metaInfo: {
    title: 'MARCSTORCH.COM | ABOUT'
  },
  mounted () {
    // this.fetchData('about')
  },
  methods: {
    onCollapsible (item) {
      if (this.activeCollapsible && item !== this.activeCollapsible) {
        // this.activeCollapsible.clear()
      }
      this.activeCollapsible = item
    }
  }
}
</script>

<style></style>
